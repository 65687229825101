import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import ClimateSurveyChart from './chart.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AuthorBlock title="Cindy Beals" subtitle="Director of Learning" mdxType="AuthorBlock">
      <p>{`  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d96684a13003e39bf26e4119d235d896/b4294/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQb/xAAYAQACAwAAAAAAAAAAAAAAAAACAwABBP/aAAwDAQACEAMQAAABjPp9AU5o0Yt+tLzO1YfLH//EABwQAAICAgMAAAAAAAAAAAAAAAEDAAIEERITIv/aAAgBAQABBQIDZti2rSLOrsOlTGT2FnhPERDgosbZhSgMp//EABkRAQACAwAAAAAAAAAAAAAAAAEAAhAREv/aAAgBAwEBPwGgJgdE5Z//xAAZEQEAAgMAAAAAAAAAAAAAAAABAAIQESH/2gAIAQIBAT8Buo4ezc//xAAcEAACAgIDAAAAAAAAAAAAAAAAAREhAhASMZH/2gAIAQEABj8CgnSY3Wr6RlGnTcl+HJ5M/8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAITFBEP/aAAgBAQABPyFUHZePgD2AwZZgs8A0XtEMC0nCyNcTAcYDrnEQAvAJ/9oADAMBAAIAAwAAABDnBr7/xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhMf/aAAgBAwEBPxBQtsOZ7Ddv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxBASIU1Zen/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFxUZGx/9oACAEBAAE/EF2hVL9B2/YlNM0im+oclrQVUjuWmZA4XglAdKkecTdHqFuoilIu4kScBwlrKoouCf/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/d96684a13003e39bf26e4119d235d896/b4294/01.jpg",
              "srcSet": ["/static/d96684a13003e39bf26e4119d235d896/f93b5/01.jpg 300w", "/static/d96684a13003e39bf26e4119d235d896/b4294/01.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`In the spring, with the emergence of COVID-19, people across the globe made significant adjustments to mitigate the spread of the virus.  PSI began its journey into emergency distance learning after February break, by piloting efforts with students and faculty in self-isolation. During this pilot, we prepared students still on-campus to use technology, such as Zoom, which would become a major platform for learning through the remainder of the year.`}</p>
    </blockquote>
    <p>{`During campus closure, we adjusted our fundamental mode of instruction, and, at the same time, remained true to our mission, by providing  an academically challenging programme in a learner-centred environment. The mission emphasises  personal excellence in the context of global awareness; exactly the dynamic of our current world environment. In our distance learning programme, we continue to educate well-rounded global citizens, and encourage the pursuit of individual passions, such as with our Grade 5 Exhibition.  Our core values also rose into prominence. They are always central to  our decision making and help us in our interactions, and understanding of and appreciation for individuality within our diverse community. The strong foundation they provide in a time of crisis is clear.`}</p>
    <p>{`Our strategic plan goals are particularly well-aligned to prosper in a distance learning environment.  Learners found greater opportunities for personalisation while more learning occurred asynchronously (accessible anytime).  With a focus on essential learning, development of thinking took prominence in the various learner experiences.  With adjustments to the timetable and a focus on wellbeing, we ensured that students were learning more about being balanced and experiencing the benefits this brings.`}</p>
    <ClimateSurveyChart mdxType="ClimateSurveyChart" />
    <p>{`During the climate survey in the spring, parents, students, faculty and staff were asked about their level of satisfaction with the Distance Learning Programme.  With 78% of parents agreeing or strongly agreeing that they were satisfied , one secondary parent wrote, "Distance learning IS NOT easy for all students. I prefer classroom instruction whenever possible. Good work from PSI anyway in the difficult situation."  We have begun preparations for an enhanced programme if needed for the fall, taking into account comments like the one from a primary parent who stated, “The adjustments to the amount of work have been good. It was way too much at the beginning, and it is more manageable now. It would be helpful to have more "social" time when they are in class together."`}</p>
    <p>{`Communications was one area where respondents to the climate survey were more pleased with 91% of parents, 84% of primary students, 82% of secondary students, 97% of faculty and 97% of staff agreeing with satisfaction on communication during the pandemic.  Still, we have seen areas for improvement as indicated by this primary student who stated, “"I think it's a good programme but what's challenging is that, once you go to this programme there's a link to another programme and that continues, though I like how it 's organized." This concern is already addressed with the introduction of a new Learning Management System, Toddle.`}</p>
    <p>{`77% of parents agreed, 74% of students in grades 3-5 agreed and 82% of secondary students agreed or strongly agreed that they were receiving appropriate support for their well being.  Typical comments by secondary students include, “Its very hard with all the stress."  To enhance this further, beginning this fall, the secondary school will be introducing a new advisory programme, where small groups of 6-10 students will meet daily with a teacher with a focus on wellbeing.`}</p>
    <p>{`Moving forward, we shall continue to improve and strengthen the online learning experience;including scenarios for remote learning, blended learning, and on-campus learning.  In addition to the approaches indicated above, the school is responding to feedback from students, parents and teachers for a desire for increased personalised learning, and more one-on-one and small group meetings between teachers and students -  regardless of the model by which learning is being delivered.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      